.flex-fill {
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-row {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

.flex-col {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
}

.flex {
  flex: 1 1 0%;
  box-sizing: border-box;
}

.flex-50 {
  max-width: 50%;
}

.space-between-center {
  align-items: center;
  justify-content: space-between;
}

.space-evenly-center {
  align-items: center;
  justify-content: space-evenly;
}

.space-around-center {
  align-items: center;
  justify-content: space-around;
}

.start-center {
  align-items: center;
  justify-content: start;
}

.center-start {
  align-items: start;
  justify-content: center;
}

.start-end {
  align-items: end;
  justify-content: start;
}

.start-start {
  align-items: start;
  justify-content: start;
}


.center-center {
  justify-content: center;
  align-items: center;
}

.end-center {
  justify-content: end;
  align-items: center;
}

.end-end {
  justify-content: end;
  align-items: end;
}

.end-start {
  justify-content: end;
  align-items: start;
}
